import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Menu, Modal, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import RankingChart from './RankingChart'
import RankingPoolForm from '../form'
import { SettingOutlined } from '@ant-design/icons'
import DepositForm from '../form/Deposit'
import WithdrawForm from '../form/Withdraw'
import { useGuild } from '~/views/app/redux/hooks/useGuild'
import { formatNumber } from '~/helpers/common'
import { useSector } from '~/views/app/redux/hooks/useSector'
import { useUser } from '~/views/app/redux/hooks/useUser'
import { useQueryState } from '~/common/hooks/useQueryState'
import { getCurrentWeekRange } from '~/common/constants'
import PageSortRankingPersonal from './PageDataPersonal'
import PageSortRankingGuild from './PageDataGuild'

function RankingPoolDetail(props) {
  const { t } = useTranslation()
  const { idCycle, isShowModal, onClose, refreshData, ranking, listPools } =
    props
  const [modalDeposit, setModalDeposit] = useState({
    id: null,
    isOpenModal: false,
  })
  const [listCheckRanking, setListCheckRanking] = useState('Sector')
  const { page, pageSize } = useQueryState()
  const [keywordGuildMonth, setKeywordGuildMonth] = useState('')

  const [keywordUserCycle, setKeywordUserCycle] = useState('')
  const [keywordUserWeekly, setKeywordUserWeekly] = useState('')

  const {
    actions,
    data: { listGuild, isLoading },
  } = useGuild()
  const {
    actions: actionsUseUser,
    data: { listUserByWallet },
  } = useUser()
  const {
    actions: { getListSectors },
    data: { listSector },
  } = useSector()

  useEffect(() => {
    const params = {
      guild_name: listCheckRanking === 'GuildMonth' && keywordGuildMonth,
    }

    actions.getListGuilds(params)
  }, [keywordGuildMonth, listCheckRanking])

  const [modalWithdraw, setModalWithdraw] = useState({
    id: null,
    isOpenModal: false,
  })

  const handleClose = () => {
    onClose()
  }
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    ranking: {},
  })
  const onShowModalForm = (id, ranking) => {
    setModalForm({
      id,
      isOpenModal: true,
      ranking: ranking,
    })
  }
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      ranking: {},
    })
  }

  const onShowModalDeposit = () => {
    setModalDeposit({
      isOpenModal: true,
    })
  }

  const onCloseModalDeposit = () => {
    setModalDeposit({
      isOpenModal: false,
    })
  }

  const onShowModalWithdraw = () => {
    setModalWithdraw({
      isOpenModal: true,
    })
  }

  const onCloseModalWithdraw = () => {
    setModalWithdraw({
      isOpenModal: false,
    })
  }

  const PersonalItems = [
    {
      key: 'Personal',
      label:
        listCheckRanking === 'PersonalCycle'
          ? 'Personal (Cycle)'
          : listCheckRanking === 'PersonalWeekly'
          ? 'Personal (Weekly)'
          : 'Personal',
      children: [
        {
          key: 'PersonalWeekly',
          label: 'Weekly',
        },
        {
          key: 'PersonalCycle',
          label: 'Cycle',
        },
      ],
    },
  ]
  const [activeMenuKey, setActiveMenuKey] = useState('')
  const handleMenuClick = (key) => {
    setActiveMenuKey(key)
    setListCheckRanking(key)
  }
  const handleSearchMonth = (e) => {
    setKeywordGuildMonth(e.target.value)
  }
  const handleSearchUserCycle = (e) => {
    setKeywordUserCycle(e.target.value)
  }
  const handleSearchUserWeekly = (e) => {
    setKeywordUserWeekly(e.target.value)
  }

  const filterCyclePool = listPools?.map((item) => item.cycle)

  const TotalExplorationScoreMonth = (guildScore) => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()

    const filteredScores = guildScore?.filter((item) => {
      const fromDate = new Date(item.from_date * 1000)
      return (
        fromDate.getFullYear() === currentYear &&
        fromDate.getMonth() === currentMonth
      )
    })

    const filteredCycleScores = filteredScores?.filter((item) =>
      filterCyclePool?.includes(Number(item.cycle)),
    )
    const total = filteredCycleScores?.reduce(
      (acc, item) => acc + item.exploration_score,
      0,
    )

    return total.toFixed(0) || 0
  }

  const TotalRankedScoreMonth = (guildScore) => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()

    const filteredScores = guildScore?.filter((item) => {
      const fromDate = new Date(item.from_date * 1000)
      return (
        fromDate.getFullYear() === currentYear &&
        fromDate.getMonth() === currentMonth
      )
    })
    const filteredCycleScores = filteredScores?.filter((item) =>
      filterCyclePool?.includes(Number(item.cycle)),
    )
    const total = filteredCycleScores?.reduce(
      (acc, item) => acc + item.ranked_score,
      0,
    )

    return total.toFixed(0) || 0
  }

  const TotalRankingGuildMonth = (ExplorationScore, RankScore) => {
    const total = Number(ExplorationScore) + Number(RankScore)
    return total
  }
  const filterGuild = listGuild?.filter((item) => {
    return item?.is_draft === false
  })

  const sortedDataRankingGuildMonth = filterGuild
    ?.map((guild) => {
      const explorationScore = TotalExplorationScoreMonth(guild.guild_score)
      const rankedScore = TotalRankedScoreMonth(guild.guild_score)
      const totalScoreRankingGuildMonth = TotalRankingGuildMonth(
        explorationScore,
        rankedScore,
      )

      return {
        ...guild,
        totalScoreRankingGuildMonth: totalScoreRankingGuildMonth,
      }
    })
    .sort(
      (a, b) => b.totalScoreRankingGuildMonth - a.totalScoreRankingGuildMonth,
    )

  //xử lý phần sector

  useEffect(() => {
    const params = {
      start_date: ranking?.timeStart,
    }
    getListSectors(params)
  }, [ranking])

  const TotalRankedScoreSector = (listSector, idCycle) => {
    // Lọc các điểm số guild theo cycle
    const filteredScores = listSector?.filter(
      (item) => Number(item.sector_cycle_id) === idCycle,
    )
    // Tính tổng ranked_score cycle
    const total = filteredScores?.reduce(
      (acc, item) => acc + item.ranked_score,
      0,
    )

    return total.toFixed(0) || 0
  }
  const TotalExplorationScoreSector = (listSector, idCycle) => {
    // Lọc các điểm số guild theo cycle
    const filteredScores = listSector?.filter(
      (item) => Number(item.sector_cycle_id) === idCycle,
    )
    // Tính tổng exploration_score cycle
    const total = filteredScores?.reduce(
      (acc, item) => acc + item.exploration_score,
      0,
    )
    return total.toFixed(0) || 0
  }
  const TotalRankingSector = (ExplorationScore, RankScore) => {
    const total = Number(ExplorationScore) + Number(RankScore)
    return total
  }

  const sortedDataRankingSector = listSector?.data
    ?.map((data) => {
      const explorationScore = TotalExplorationScoreSector(
        data?.sector_cycle,
        idCycle,
      )
      const rankedScore = TotalRankedScoreSector(data?.sector_cycle, idCycle)
      const totalScoreRankingSector = TotalRankingSector(
        explorationScore,
        rankedScore,
      )

      return {
        ...data,
        totalScoreRankingSector: totalScoreRankingSector,
      }
    })
    .sort((a, b) => b.totalScoreRankingSector - a.totalScoreRankingSector)

  const backgroundColor = (value) => {
    switch (value) {
      case 1:
        return 'rgba(12, 200, 254, 0.4)'
      case 2:
        return 'rgba(79, 252, 88, 0.4)'
      case 3:
        return 'rgba(238, 1, 9, 0.4)'
      default:
        return ''
    }
  }
  //xử lý phần personal
  const [ScoreRankingPersonal, setScoreRankingPersonal] = useState([])

  const ListUserOfCycle = listSector?.data
    ?.map((item) => {
      const sectorMembersCount = item?.sector_cycle?.find(
        (item) => Number(item.sector_cycle_id) === idCycle,
      )
      const filteredAddresses = [
        ...new Set(
          sectorMembersCount?.sector_member?.filter(
            (address) =>
              address !== '' && address !== null && address !== undefined,
          ),
        ),
      ]
      return filteredAddresses
    })
    .flat()
  const range = getCurrentWeekRange()

  const [isLoadingScore, setIsloadingScore] = useState(false)
  useEffect(() => {
    if (ListUserOfCycle && ListUserOfCycle?.length > 0) {
      const paramScoreSnapShotPersonal = {
        score_type: 'SECTOR',
        from_date:
          listCheckRanking === 'PersonalCycle'
            ? ranking?.timeStart
            : range.startTimestamp,
        to_date:
          listCheckRanking === 'PersonalCycle'
            ? ranking?.timeEnd
            : range.endTimestamp,
      }
      setIsloadingScore(true)
      actionsUseUser.getScoreSnapShotAddress(
        paramScoreSnapShotPersonal,
        (res) => {
          if (res) {
            setTimeout(() => {
              setScoreRankingPersonal(res?.data?.score_snapshot)
              setIsloadingScore(false)
            }, 1000)
          }
        },
      )
    }
  }, [listCheckRanking, ranking])

  useEffect(() => {
    if (ListUserOfCycle && ListUserOfCycle?.length > 0) {
      const params = {
        search:
          listCheckRanking === 'PersonalCycle'
            ? keywordUserCycle
            : keywordUserWeekly,
        allWallets: ListUserOfCycle,
        pageSize: pageSize,
        page,
      }
      actionsUseUser.getUserByWallet(params)
    }
  }, [listSector, listCheckRanking, keywordUserCycle, keywordUserWeekly])

  const findScore = (wallet, data, type) => {
    const record = data.find((item) => item.user === wallet)

    if (!record) return null

    if (type === 'explore_score') {
      return record.explore_score
    } else if (type === 'ranked_score') {
      return record.ranked_score
    } else {
      return null
    }
  }

  const mergedDataListUser = listUserByWallet?.map((player) => {
    const userWallet = player.address_wallet
    const explorationScore = findScore(
      userWallet,
      ScoreRankingPersonal,
      'explore_score',
    )
    const rankedMatchScore = findScore(
      userWallet,
      ScoreRankingPersonal,
      'ranked_score',
    )

    const total = Number(explorationScore || 0) + Number(rankedMatchScore || 0)
    //xử lý sector
    const filteredDataListSector = listSector?.data
      ?.map((sector) => ({
        ...sector,
        sector_cycle: sector?.sector_cycle.filter(
          (cycle) =>
            cycle.sector_member.includes(player?.address_wallet) &&
            Number(cycle.sector_cycle_id) === idCycle,
        ),
      }))
      .find((sector) => sector?.sector_cycle?.length > 0)

    //xử lý guild
    const dataListGuildUser = sortedDataRankingGuildMonth
      ?.map((guild, index) => ({
        ...guild,
        guild_member: guild.guild_member.filter((data) =>
          data.user_wallet.includes(player?.address_wallet),
        ),
        TopGuildRank: index + 1,
      }))
      .find((guild) => guild?.guild_member?.length > 0)

    return {
      ...player,
      explorationScorePersonal: explorationScore ? explorationScore : 0,
      rankedMatchScorePersonal: rankedMatchScore ? rankedMatchScore : 0,
      totalPersonal: total,
      filteredDataListSector: filteredDataListSector,
      listGuildUser: dataListGuildUser ? dataListGuildUser : null,
    }
  })

  const sortRankingPersonalRaking = mergedDataListUser.sort((a, b) => {
    return b.totalPersonal - a.totalPersonal
  })
  const getRankingGuild = (guildScore, idCycle) => {
    if (!guildScore) return null
    const data = guildScore.find((item) => Number(item.cycle) === idCycle)
    return data
  }

  return (
    <>
      <Modal
        title={'Ranking Detail'}
        onCancel={handleClose}
        open={isShowModal}
        width={1100}
        centered
        destroyOnClose
        footer={null}
      >
        <div>
          <Row>
            <Col span={8}>
              <div>
                <RankingChart dataRanking={ranking?.subPools} />
              </div>
              <div className="mt-[15%]">
                <div className="flex gap-2 justify-center items-center">
                  <Button onClick={() => onShowModalDeposit()}>Deposit</Button>
                  <Button onClick={() => onShowModalWithdraw()}>
                    Withdraw
                  </Button>
                  <Button
                    onClick={() => onShowModalForm(ranking?.cycle, ranking)}
                  >
                    <SettingOutlined />
                  </Button>
                </div>
              </div>
              <div className="flex justify-center text-[24px] mt-3 mb-3">
                Reward Status
              </div>
              <div
                style={{
                  border: '2px solid #fff',
                  padding: '10px',
                  marginBottom: '12px',
                  borderRadius: '10px',
                }}
              >
                <div className="flex justify-center text-[20px]">
                  Claimed in Sector
                </div>
                <div className="flex justify-center text-[16px] ">
                  5,340/10,000 OAS
                </div>
                <div className="flex justify-center text-[16px] ">
                  500/1,000 Players
                </div>
              </div>
              <div
                style={{
                  border: '2px solid #fff',
                  padding: '10px',
                  borderRadius: '10px',
                }}
              >
                <div className="flex justify-center text-[20px]">
                  Claimed in Total
                </div>
                <div className="flex justify-center text-[16px]">
                  20,000/40,000 OAS
                </div>
                <div className="flex justify-center text-[16px]">
                  500/4,000 Players
                </div>
              </div>
            </Col>
            <Col span={1}></Col>
            <Col span={15}>
              <div
                className="flex justify-center items-center mb-2"
                style={{ fontFamily: 'Rexliafree' }}
              >
                <img
                  className="w-[25px] h-[25px] mr-2 mt-[2px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                  alt=""
                />
                <div className="text-[25px]">Ranking</div>
              </div>

              {listCheckRanking === 'PersonalWeekly' && (
                <PageSortRankingPersonal
                  backgroundColor={backgroundColor}
                  sortRankingPersonalRaking={sortRankingPersonalRaking}
                  getRankingGuild={getRankingGuild}
                  idCycle={idCycle}
                  handleSearchUser={handleSearchUserWeekly}
                  keywordUser={keywordUserWeekly}
                  isLoadingScore={isLoadingScore}
                  sortRankingGuild={sortedDataRankingGuildMonth}
                />
              )}
              {listCheckRanking === 'PersonalCycle' && (
                <PageSortRankingPersonal
                  backgroundColor={backgroundColor}
                  sortRankingPersonalRaking={sortRankingPersonalRaking}
                  getRankingGuild={getRankingGuild}
                  idCycle={idCycle}
                  handleSearchUser={handleSearchUserCycle}
                  keywordUser={keywordUserCycle}
                  isLoadingScore={isLoadingScore}
                  sortRankingGuild={sortedDataRankingGuildMonth}
                />
              )}
              {listCheckRanking === 'GuildMonth' && (
                <PageSortRankingGuild
                  sortRankingGuild={sortedDataRankingGuildMonth}
                  handleSearchGuild={handleSearchMonth}
                  keywordGuild={keywordGuildMonth}
                  isLoading={isLoading}
                  TotalRankingGuild={TotalRankingGuildMonth}
                  TotalRankedScore={TotalRankedScoreMonth}
                  TotalExplorationScore={TotalExplorationScoreMonth}
                />
              )}
              {listCheckRanking === 'Sector' && (
                <div style={{ fontFamily: 'Rexliafree' }}>
                  <Spin spinning={isLoading}>
                    {sortedDataRankingSector?.map((item, index) => (
                      <div
                        className="guildInfo"
                        style={{
                          maxHeight: '567px',
                          overflowY: 'auto',
                          padding: '0 20px',
                        }}
                      >
                        <div
                          className="mb-2"
                          style={{
                            border: '2px solid rgba(12, 200, 254, 1)',
                            padding: '10px',
                            background: backgroundColor(item?.sector),
                            borderRadius: '20px',
                            position: 'relative',
                          }}
                        >
                          <img
                            className="absolute left-0 top-0 w-full h-full "
                            src={`${process.env.PUBLIC_URL}/svgs/guild/logoSector/${item?.sector}.svg`}
                            alt=""
                          />
                          <Row>
                            <Col span={17}>
                              <Row>
                                <Col span={5} className="flex  items-center">
                                  <img
                                    className="w-[55px] h-[55px] mr-2 "
                                    src={`${process.env.PUBLIC_URL}/svgs/guild/sector/${item?.sector}.svg`}
                                    alt=""
                                  />
                                </Col>
                                <Col span={14} className="flex items-center  ">
                                  <div className="text-[27px]">
                                    {item.sector_name}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              span={7}
                              className=" flex items-center justify-center"
                              style={{
                                border: '1px solid rgba(0, 0, 0, 0.8)',
                                background: 'rgba(0, 0, 0, 0.8)',
                                borderRadius: '10px',
                                height: '55px',
                              }}
                            >
                              <img
                                className="w-[25x] h-[25px] mr-2"
                                src={`${process.env.PUBLIC_URL}/svgs/guild/${
                                  index + 1
                                }.svg`}
                                alt=""
                              />
                              <div className="flex items-center ">
                                <div className="text-[45px] mb-[12px] ">
                                  {index + 1}
                                </div>
                                <div className="text-[25px]">
                                  {index + 1 === 1
                                    ? 'st'
                                    : index + 1 === 2
                                    ? 'nd'
                                    : 'rd'}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={17} className="text-[25px]">
                              Sector Score
                            </Col>
                            <Col span={7} className="text-[25px] text-end">
                              {formatNumber(
                                TotalRankingSector(
                                  TotalRankedScoreSector(
                                    item?.sector_cycle,
                                    idCycle,
                                  ),
                                  TotalExplorationScoreSector(
                                    item?.sector_cycle,
                                    idCycle,
                                  ),
                                ),
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={17} className="text-[20px] flex">
                              <img
                                className="w-[20px] h-[20px] mt-[2px]"
                                src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                                alt=""
                              />
                              <div className="ml-2">Ranked Match</div>
                            </Col>
                            <Col span={7} className="text-[20px] text-end">
                              {formatNumber(
                                TotalRankedScoreSector(
                                  item?.sector_cycle,
                                  idCycle,
                                ),
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={17} className="text-[20px] flex">
                              <img
                                className="w-[20px] h-[20px] mt-[2px]"
                                src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                                alt=""
                              />
                              <div className="ml-2">Exploration</div>
                            </Col>
                            <Col span={7} className="text-[20px] text-end">
                              {formatNumber(
                                TotalExplorationScoreSector(
                                  item?.sector_cycle,
                                  idCycle,
                                ),
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                  </Spin>
                </div>
              )}
              <div>
                <Row className="mt-8">
                  <Col span={5}></Col>
                  <Col
                    span={14}
                    className="flex gap-6 justify-center items-start "
                  >
                    <Menu
                      onClick={(e) => handleMenuClick(e.key)}
                      selectedKeys={[activeMenuKey]}
                      style={{
                        fontFamily: 'Rexliafree',
                        fontWeight: '400',
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '40px',
                        backgroundColor: 'rgba(0, 0, 0, 0.45)',
                        border: '1px solid rgb(230, 235, 241)',
                        borderRadius: '0.625rem',
                      }}
                      mode="vertical"
                      items={PersonalItems}
                    />
                    <Button
                      style={{
                        color: listCheckRanking === 'GuildMonth' && '#1677ff',
                      }}
                      onClick={() => {
                        setActiveMenuKey('GuildMonth')
                        setListCheckRanking('GuildMonth')
                      }}
                    >
                      Guild (Monthly)
                    </Button>
                    <Button
                      style={{
                        color: listCheckRanking === 'Sector' && '#1677ff',
                      }}
                      onClick={() => {
                        setActiveMenuKey('Sector')
                        setListCheckRanking('Sector')
                      }}
                    >
                      Sector (Cycle)
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        {modalForm.isOpenModal && (
          <RankingPoolForm
            isShowModal={modalForm.isOpenModal}
            onClose={onCloseModalForm}
            refreshData={refreshData}
            id={modalForm.id}
            ranking={modalForm?.ranking}
          />
        )}
      </Modal>

      {modalDeposit.isOpenModal && (
        <DepositForm
          isShowModal={modalDeposit.isOpenModal}
          onClose={onCloseModalDeposit}
          // refreshData={refreshData}
          // id={modalForm.id}
          // ranking={modalForm?.ranking}
        />
      )}

      {modalWithdraw.isOpenModal && (
        <WithdrawForm
          isShowModal={modalWithdraw.isOpenModal}
          onClose={onCloseModalWithdraw}
          // refreshData={refreshData}
          // id={modalForm.id}
          // ranking={modalForm?.ranking}
        />
      )}
    </>
  )
}

RankingPoolDetail.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default RankingPoolDetail
