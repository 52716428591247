import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Form, Input, Row, Space } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import UserGuild from './UserDetail'
import GuildDetail from './GuildDetail'
import { DeleteFilled } from '@ant-design/icons'
import { useGuild } from '../../redux/hooks/useGuild'
import ConfirmDeleteGuild from './confirmDeleteGuild'
import {
  dataGuildLevel,
  getEndOfMonthUTC,
  getStartOfMonthUTC,
} from '~/common/constants'
import { useUser } from '../../redux/hooks/useUser'
import { usePools } from '../../redux/hooks/usePools'
import { formatNumber } from '~/helpers/common'

const ManagementGuilds = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  const [modalForm, setModalForm] = useState({
    data: null,
    isOpenModal: false,
  })
  const [modalFormProfileGuild, setModalFormProfileGuild] = useState({
    data: null,
    isOpenModal: false,
  })
  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
  })

  const showModalDelete = (content, data) => {
    setIsModalOpenDelete({
      content: content,
      data: data,
      isOpenModal: true,
    })
  }
  const currentTimeUTC = Math.floor(Date.now() / 1000)

  const {
    actions,
    data: { listGuild, isLoading },
  } = useGuild()
  const filterGuild = listGuild?.filter((item) => {
    return item?.is_draft === false
  })

  const paginatedData = filterGuild?.slice(
    (page - 1) * pageSize,
    page * pageSize,
  )
  const usersWithRole0 = filterGuild.flatMap((guild) =>
    guild.guild_member.map((member) => member.user_wallet),
  )

  const {
    actions: getListUserByWallet,
    data: { listUserByWallet },
  } = useUser()

  useEffect(() => {
    const params = {
      search: keyword,
      pageSize: pageSize,
      page,
      allWallets: usersWithRole0,
    }
    getListUserByWallet.getUserByWallet(params)
  }, [listGuild, keyword, pageSize, page])

  const refreshData = () => {
    const params = {
      guild_name: keyword,
    }

    actions.getListGuilds(params)
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword])
  const onRemove = (data) => {
    showModalDelete(t('cancel_modal.question', { question: '' }), data)
  }
  const {
    actions: poolsActions,
    data: { listPool },
  } = usePools()
  useEffect(() => {
    poolsActions.getListPools()
  }, [])

  const currentCycle = useMemo(
    () =>
      listPool?.find(
        (cycle) =>
          currentTimeUTC >= cycle.timeStart && currentTimeUTC <= cycle.timeEnd,
      ),
    [listPool, currentTimeUTC],
  )

  const columns = [
    {
      title: (
        <div className="flex items-center">
          <img
            className="w-[25px] h-[25px] mr-2 mt-[2px]"
            src={`${process.env.PUBLIC_URL}/svgs/guild/guild.svg`}
            alt=""
          />
          {t('managementGuilds.form.nameGuild')}
        </div>
      ),
      dataIndex: 'guild_name',
      key: 'guild_name',
      render: (guild_name) => {
        return (
          <div className="" style={{ fontFamily: 'Rexliafree' }}>
            {guild_name}
          </div>
        )
      },
    },
    {
      title: t('managementGuilds.form.level'),
      dataIndex: 'guild_level',
      key: 'guild_level',
      render: (guild_level) => {
        return <div style={{ fontFamily: 'Rexliafree' }}>{guild_level}</div>
      },
    },

    {
      title: t('managementGuilds.form.rank'),
      dataIndex: 'rank',
      key: 'rank',
      render: (_, _record) => {
        return (
          <div className="flex" style={{ fontFamily: 'Rexliafree' }}>
            {_record?.guild_placement ? <>{_record?.guild_placement}</> : 'ー'}
          </div>
        )
      },
    },

    {
      title: (
        <div className="flex ml-[3rem]">{t('managementGuilds.form.score')}</div>
      ),
      dataIndex: 'score',
      width: 300,
      key: 'score',
      render: (_, data) => {
        const totalData = data?.guild_score?.map((item) => ({
          ...item,
          totalScore: item.exploration_score + item.ranked_score,
        }))

        const currentMonthEntriesTotal = totalData.find((item) => {
          const fromDate = parseInt(item.from_date, 10)
          const toDate = parseInt(item.to_date, 10)
          return (
            fromDate <= getEndOfMonthUTC() && toDate >= getStartOfMonthUTC()
          )
        })
        const totalScoreCycle = totalData
          .filter((item) => Number(item.cycle) === currentCycle?.cycle)
          .reduce((sum, item) => sum + item.totalScore, 0)
        const totalScoreGuild = totalData.reduce(
          (sum, item) => sum + item.totalScore,
          0,
        )

        return (
          <div className="flex-center" style={{ fontFamily: 'Rexliafree' }}>
            {data?.guild_score?.length > 0 ? (
              <Row gutter={24}>
                <Col span={8} className="flex">
                  <div className="flex items-center justify-center">
                    <img
                      className="w-[20px] h-[20px] mr-3 "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                      alt=""
                    />
                  </div>
                  <div>
                    <div>Total</div>
                    <div>Month</div>
                    <div>Cycle</div>
                  </div>
                </Col>
                <Col span={16}>
                  <React.Fragment>
                    <div className="flex flex-col mb-1 items-start">
                      <div className="flex items-center h-[25px] flex-col  ">
                        <div>{formatNumber(totalScoreGuild)}</div>
                        <div>
                          {formatNumber(currentMonthEntriesTotal.totalScore)}
                        </div>
                        <div>{formatNumber(totalScoreCycle)}</div>
                      </div>
                    </div>
                  </React.Fragment>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      title: (
        <div className="flex items-center">
          <img
            className="w-[15px] h-[15px] mr-2 mt-[2px]"
            src={`${process.env.PUBLIC_URL}/svgs/guild/master.svg`}
            alt=""
          />
          {t('managementGuilds.form.master')}
        </div>
      ),
      dataIndex: 'guild_member',
      key: 'guild_member',
      render: (_record) => {
        const findMemberRole = _record.find(
          (member) => member.member_role === 0,
        )

        if (findMemberRole) {
          const user = listUserByWallet.find(
            (user) => user.address_wallet === findMemberRole.user_wallet,
          )

          if (user) {
            return (
              <div
                style={{
                  fontFamily: 'Rexliafree',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  className="w-[30px] h-[30px] mr-2"
                  src={
                    user.avatar ||
                    `${process.env.PUBLIC_URL}/default-avatar.png`
                  }
                  alt=""
                />
                <span className="max-w-[80px] overflow-hidden whitespace-nowrap text-ellipsis">
                  {user.user_name || 'Anonymous'}
                </span>
              </div>
            )
          }
        }
        return 'ー'
      },
    },
    {
      title: t('managementGuilds.form.member'),
      dataIndex: 'guild_member',
      key: 'guild_member',
      render: (_record, member) => {
        const maxMembers = dataGuildLevel(member?.guild_level)?.maxMembers
        const uniqueGuildMembers = _record?.filter(
          (member, index, self) =>
            index ===
            self.findIndex((m) => m.user_wallet === member.user_wallet),
        )

        return (
          <div style={{ fontFamily: 'Rexliafree' }}>
            {uniqueGuildMembers?.length}/{maxMembers}
          </div>
        )
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={
                <img
                  className="w-[20px] h-[20px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/infoGuild.svg`}
                  alt=""
                />
              }
              onClick={() => {
                onShowModalFormGuild(data, data)
              }}
            />
            <Button
              type="ghost"
              loading={false}
              onClick={() => onShowModalForm(data, data)}
              icon={
                <img
                  className="w-[20px] h-[20px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/userGuild.svg`}
                  alt=""
                />
              }
            />

            <Button
              type="ghost"
              loading={false}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
              onClick={() => {
                onRemove(data)
              }}
            />
          </Space>
        )
      },
    },
  ]

  const onShowModalForm = (data) => {
    setModalForm({
      data,
      isOpenModal: true,
    })
  }

  const onShowModalFormGuild = (data) => {
    setModalFormProfileGuild({
      data,
      isOpenModal: true,
    })
  }

  const onCloseModalForm = () => {
    setModalForm({
      data: null,
      isOpenModal: false,
    })
  }
  const onCloseModalFormGuild = () => {
    setModalFormProfileGuild({
      data: null,
      isOpenModal: false,
    })
  }

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      data: null,
      isOpenModal: false,
    })
  }

  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}></Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Form
                onFinish={handleSearch}
                className="w-full sm:w-auto"
                form={formSearch}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.search')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Input className="w-full" placeholder={t('common.search')} />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={paginatedData}
        loading={isLoading}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: filterGuild?.length,
          keyword,
          setKeyword,
        }}
      />

      {modalForm.isOpenModal && (
        <UserGuild
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          data={modalForm.data}
          currentCycle={currentCycle}
          listPool={listPool}
          listUserByWallet={listUserByWallet}
        />
      )}
      {modalFormProfileGuild.isOpenModal && (
        <GuildDetail
          isShowModal={modalFormProfileGuild.isOpenModal}
          onClose={onCloseModalFormGuild}
          data={modalFormProfileGuild.data}
          listUserByWallet={listUserByWallet}
        />
      )}

      <ConfirmDeleteGuild
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        refreshData={refreshData}
        dataGuild={isModalOpenDelete.data}
        t={t}
      />
    </div>
  )
}

export default ManagementGuilds
