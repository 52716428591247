import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Empty, Spin } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { converUserRankUser } from '~/common/constants'
import { formatNumber } from '~/helpers/common'

const PageSortRankingPersonal = ({
  sortRankingPersonalRaking,
  backgroundColor,
  getRankingGuild,
  idCycle,
  handleSearchUser,
  keywordUser,
  sortRankingGuild,
  isLoadingScore,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const totalPages = Math.ceil(sortRankingPersonalRaking?.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentItems = sortRankingPersonalRaking?.slice(startIndex, endIndex)

  // Handle page change
  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }
  useEffect(() => {
    if (totalPages === 0) {
      setCurrentPage(0)
    } else {
      setCurrentPage(1)
    }
  }, [totalPages])
  return (
    <div style={{ fontFamily: 'Rexliafree' }}>
      <Spin spinning={isLoadingScore}>
        <div
          className="guildInfo"
          style={{
            maxHeight: '515px',
            overflowY: 'auto',
            padding: '0 20px',
          }}
        >
          {currentItems?.length > 0 ? (
            currentItems.map((item, index) => (
              <div
                className="mb-2"
                style={{
                  border: '2px solid rgba(12, 200, 254, 1)',
                  padding: '10px',
                  background: backgroundColor(
                    item?.filteredDataListSector?.sector,
                  ),
                  borderRadius: '20px',
                  position: 'relative',
                }}
              >
                {index + 1 > 0 && index + 1 < 7 && (
                  <img
                    className="absolute left-[-20px] top-[-3px] w-[45px] h-[45px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/level/${
                      index + 1
                    }.svg`}
                    alt=""
                    style={{ zIndex: 1 }}
                  />
                )}

                <img
                  className="absolute left-0 top-0 w-full h-full "
                  src={`${process.env.PUBLIC_URL}/svgs/guild/logoSector/${item?.filteredDataListSector?.sector}.svg`}
                  alt=""
                />

                <Row>
                  <Col span={16}>
                    <Row>
                      <Col
                        span={5}
                        className="flex justify-center items-center"
                      >
                        <img
                          className="w-[50px] h-[50px] mr-2 mt-[2px]"
                          // src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                          src={item.avatar ?? '../svgs/camera.svg'}
                          alt=""
                        />
                      </Col>
                      <Col span={14} className="flex flex-col ">
                        <div className="text-[20px]  text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px] ">
                          {item.user_name}
                        </div>
                        <div className="text-[14px]">
                          {converUserRankUser(item?.player_rank)}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <Col span={8} className="flex items-center">
                        <img
                          className="w-[20px] h-[20px] mr-2 mt-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                          alt=""
                        />
                      </Col>
                      <Col span={16}>{formatNumber(item.totalPersonal)}</Col>
                    </Row>
                    <Row>
                      <Col span={8} className="flex items-center">
                        <img
                          className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                          alt=""
                        />
                      </Col>
                      <Col span={16}>
                        {formatNumber(item.rankedMatchScorePersonal)}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} className="flex items-center">
                        <img
                          className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                          alt=""
                        />
                      </Col>
                      <Col span={16}>
                        {formatNumber(item.explorationScorePersonal)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col span={16}>
                    <Row>
                      <Col
                        span={5}
                        className="flex justify-center items-center"
                      >
                        {item.listGuildUser && (
                          <img
                            className={`mr-2
                                    ${
                                      item?.listGuildUser?.guild_image?.startsWith(
                                        process.env.REACT_APP_BASE_URL_IMAGE,
                                      )
                                        ? 'h-[35px] mb-2'
                                        : 'w-[40px] h-[40px] '
                                    }
                                  `}
                            src={
                              item?.listGuildUser?.guild_image?.startsWith(
                                process.env.REACT_APP_BASE_URL_IMAGE,
                              )
                                ? item?.listGuildUser.guild_image
                                : `${process.env.PUBLIC_URL}/svgs/guild/ImageGuild/${item?.listGuildUser?.guild_image}.svg`
                            }
                            alt=""
                          />
                        )}
                      </Col>
                      <Col span={14} className="flex">
                        {item.listGuildUser ? (
                          <div
                            className="text-[18px]"
                            style={{ alignContent: 'center' }}
                          >
                            {item.listGuildUser.guild_name}
                          </div>
                        ) : (
                          <div>No Guild</div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8} className="mt-[6px]">
                    <Row>
                      <Col span={8}>
                        {getRankingGuild(
                          item?.listGuildUser?.guild_score,
                          idCycle,
                        ) && (
                          <img
                            className="w-[20px] h-[20px] mr-2 mt-[2px]"
                            src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                            alt=""
                          />
                        )}
                      </Col>
                      <Col span={16}>
                        {getRankingGuild(
                          item?.listGuildUser?.guild_score,
                          idCycle,
                        ) ? (
                          <>
                            {item?.listGuildUser?.TopGuildRank} /{' '}
                            {sortRankingGuild?.length}
                          </>
                        ) : (
                          <>- / {sortRankingGuild?.length}</>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </Spin>

      <Row className="mt-4">
        <Col span={12} className="flex justify-center items-center">
          <CaretLeftOutlined
            style={{
              fontSize: '25px',
              cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
              color: currentPage > 1 ? 'inherit' : 'black',
            }}
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          />
          <div style={{ margin: '0 15%', fontSize: '18px' }}>
            {currentPage} / {totalPages}
          </div>
          <CaretRightOutlined
            style={{
              fontSize: '25px',
              cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
              color: currentPage < totalPages ? 'inherit' : 'black',
            }}
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          />
        </Col>
        <Col span={12} className="flex justify-center items-center">
          <img
            className="w-[25px] h-[25px] mr-2 mt-[2px]"
            src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
            alt=""
          />
          <Input
            style={{ width: '133px', height: '28px' }}
            onChange={handleSearchUser}
            placeholder="Enter text..."
            value={keywordUser}
          />
          <img
            className="w-[30px] h-[30px] ml-2 cursor-pointer"
            src={`${process.env.PUBLIC_URL}/svgs/guild/searchSubmit.svg`}
            alt=""
          />
        </Col>
      </Row>
    </div>
  )
}

export default PageSortRankingPersonal
