import { all } from 'redux-saga/effects'

//Common
import watchGetRole from './common-management/get-role'
import watchGetTema from './common-management/get-tema'

//DashBoard
import watchGetDashBoardMkp from './1dashboard-management.js/1get-dashboard-mkp'
import watchGetDashBoardShop from './1dashboard-management.js/2get-dashboard-shop'
import watchGetDashBoardBit from './1dashboard-management.js/3get-dashboard-bit'
import watchGetDashBoardTrade from './1dashboard-management.js/4get-dashboard-trade'
import watchGetDashBoardItem from './1dashboard-management.js/5get-dashboard-item'
import watchGetDashBoardGame from './1dashboard-management.js/6get-dashboard-game'

//History Trade
import watchGetHistoryTrade from './2history-trade-management/1get-list-history-trade'

//Collaboration
import watchGetListCollaboration from './3collaboration-management/1get-list-collaboration'
import watchCreateCollaboration from './3collaboration-management/2create-collaboration'
import watchUpdateCollaboration from './3collaboration-management/4update-collaboration'
import watchDeleteCollaboration from './3collaboration-management/5delete-collaboration'

//Marketplace Settings
import watchGetFee from './4marketplace-settings-management/get-fee'
import watchUpdateFee from './4marketplace-settings-management/update-fee'

//Shop management
import watchGetListShop from './5shop-management/1get-list-shop'
import watchUpdatePriceShop from './5shop-management/2update-price-shop'
import watchUpdateStatusShop from './5shop-management/3update-status-shop'

//Pools management
import watchGetDepositPools from './6pools-management/1get-deposit-pools'
import watchUpdateDepositPools from './6pools-management/2update-deposit-pools'
import watchGetWithdrawPools from './6pools-management/3get-withdraw-pools'
import watchUpdateWithdrawPools from './6pools-management/4update-withdraw-pools'
import watchAddMemberSector from './10sector-management.js/2addMemberSector'
import watchDeleteMemberSector from './10sector-management.js/3deleteMemberSector'

//Admin
import watchGetListAdmin from './7admin-management/1get-list-admin'
import watchCreateAdmin from './7admin-management/2create-admin'
import watchUpdateAdmin from './7admin-management/4update-admin'
import watchDeleteAdmin from './7admin-management/5delete-admin'

// User
import watchGetListUser from './9user-management/1get-list-user'
import watchGetDetailUser from './9user-management/2get-detail-user'

//Profile
import watchGetProfileInfo from './8profile-management/1get-profile-info'
import watchUpdateEmail from './8profile-management/2update-email'
import watchUpdateAvatar from './8profile-management/3update-avatar'
import watchUpdatePhone from './8profile-management/4update-phone'
import watchSendVerifyCode from './8profile-management/5send-verify-code'
import watchUpdateName from './8profile-management/6update-name'

//gift
import watchGetListGifts from './gift-management/1get-list-gift'
import watchSendGift from './gift-management/2send-gift'
import watchGetListGuilds from './guild-management/1get-list-guild'
import watchCreatePool from './6pools-management/6create-pools'
import watchEditPool from './6pools-management/8edit-pools'
import watchClosePool from './6pools-management/7close-pools'
import watchGetListPools from './6pools-management/5get-list-pools'
import watchGetListSector from './10sector-management.js/1get-list-sector'
import watchGetBlackLists from './11blackList-management.js/1get-list-blackList'
import watchGetAddBlackLists from './11blackList-management.js/2get-list-add-blackList'
import watchCreateBlackLists from './11blackList-management.js/3create-blackList'
import watchDeleteBlackLists from './11blackList-management.js/4delete-blackList'
import watchGetListTournament from './12sponsor-tournament-management.js/1get-list-sponsor-tournament'
import watchGetUserByWallet from './9user-management/3get-user-by-wallet'
import watchDeleteGuilds from './guild-management/2delete-guild'
import watchGetScoreByWallet from './9user-management/4get-score-by-wallet'
import watchGetBalancePoc from './9user-management/5get-balancePoc'
import watchGetScoreAddress from './9user-management/6get-list-score-address'
import watchGetListMonsterAddress from './9user-management/7get-list-monster-address'
import watchGetScoreSnapshotAddress from './9user-management/8get-score-snapshot'
import watchGetTongleWhiteLists from './13whiteList-management.js/1getTongeWhiteList'
import watchChangeWhiteLists from './13whiteList-management.js/2changeToggleWhiteList'
import watchGetWhiteLists from './13whiteList-management.js/3get-list-whiteList'
import watchGetAddWhiteLists from './13whiteList-management.js/4get-list-add-whiteList'
import watchCreateWhiteLists from './13whiteList-management.js/5create-whiteList'
import watchDeleteWhiteLists from './13whiteList-management.js/6delete-whiteList'
import watchGetRankingAchievement from './9user-management/9get-ranking-achievement'

/**
 * Root saga
 */

export default function* sagas() {
  yield all([
    //Common
    watchGetRole(),
    watchGetTema(),

    //DashBoard
    watchGetDashBoardMkp(),
    watchGetDashBoardShop(),
    watchGetDashBoardBit(),
    watchGetDashBoardTrade(),
    watchGetDashBoardItem(),
    watchGetDashBoardGame(),

    //History Trade
    watchGetHistoryTrade(),

    //Collaboration
    watchGetListCollaboration(),
    watchCreateCollaboration(),
    watchUpdateCollaboration(),
    watchDeleteCollaboration(),

    //Marketplace Settings
    watchGetFee(),
    watchUpdateFee(),

    //Shop management
    watchGetListShop(),
    watchUpdatePriceShop(),
    watchUpdateStatusShop(),

    //Pools management
    watchGetDepositPools(),
    watchUpdateDepositPools(),
    watchGetWithdrawPools(),
    watchUpdateWithdrawPools(),
    watchGetListPools(),
    watchCreatePool(),
    watchEditPool(),
    watchClosePool(),
    watchAddMemberSector(),
    watchDeleteMemberSector(),

    //Admin
    watchGetListAdmin(),
    watchCreateAdmin(),
    watchUpdateAdmin(),
    watchDeleteAdmin(),

    //User
    watchGetListUser(),
    watchGetDetailUser(),
    watchGetUserByWallet(),
    watchGetScoreByWallet(),
    watchGetBalancePoc(),
    watchGetScoreAddress(),
    watchGetListMonsterAddress(),
    watchGetScoreSnapshotAddress(),
    watchGetRankingAchievement(),

    //Profile
    watchGetProfileInfo(),
    watchUpdateEmail(),
    watchUpdateAvatar(),
    watchUpdatePhone(),
    watchSendVerifyCode(),
    watchUpdateName(),

    //Gift
    watchGetListGifts(),
    watchSendGift(),

    //Guild
    watchGetListGuilds(),
    watchDeleteGuilds(),

    //Sector
    watchGetListSector(),

    //BlackList
    watchGetBlackLists(),
    watchGetAddBlackLists(),
    watchCreateBlackLists(),
    watchDeleteBlackLists(),

    //whitelist
    watchGetTongleWhiteLists(),
    watchChangeWhiteLists(),
    watchGetWhiteLists(),
    watchGetAddWhiteLists(),
    watchCreateWhiteLists(),
    watchDeleteWhiteLists(),

    //Sponsor Tournament
    watchGetListTournament(),
  ])
}
